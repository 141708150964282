var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", "max-width": "1300" } },
    [
      _c(
        "v-card",
        { staticClass: "grey lighten-4" },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "white--text",
              attrs: { flat: "", color: "#1C3144" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { tabindex: "-1", icon: "" },
                  on: { click: _vm.checkForInvoices }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.isExistingBatch
                        ? "Edit Manual Invoice"
                        : "Create Manual Invoice"
                    ) +
                    " "
                )
              ]),
              _c("v-spacer"),
              _c(
                "v-row",
                { attrs: { dense: "", justify: "end" } },
                [
                  _c(
                    "v-col",
                    { attrs: { lg: "6", xl: "6" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mt-2",
                        attrs: {
                          tabindex: "-1",
                          items: _vm.invoices,
                          "background-color": "white",
                          color: "",
                          dense: "",
                          "item-text": "ad_group.description",
                          outlined: "",
                          placeholder: _vm.invoices.length
                            ? "Saved Invoices (" + _vm.invoices.length + ")"
                            : "No Saved Invoices",
                          "return-object": ""
                        },
                        on: { change: _vm.viewInvoice },
                        model: {
                          value: _vm.invoice,
                          callback: function($$v) {
                            _vm.invoice = $$v
                          },
                          expression: "invoice"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "grey lighten-4 my-6 px-6",
              attrs: { flat: "", height: "100", fluid: "" }
            },
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "6", lg: "7", xl: "7" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-autocomplete", {
                                    ref: "adGroup",
                                    attrs: {
                                      rules: [
                                        function(v) {
                                          return !!v || "Ad Group Required"
                                        }
                                      ],
                                      items: _vm.adgroups.filter(function(ref) {
                                        var id = ref.id

                                        return id !== "ALL"
                                      }),
                                      readonly: _vm.isExistingInvoice,
                                      "auto-select-first": "",
                                      autofocus: "",
                                      "background-color": "#fff",
                                      dense: "",
                                      "item-id": "id",
                                      "item-text": "description",
                                      label: "Ad Group",
                                      outlined: "",
                                      "return-object": ""
                                    },
                                    model: {
                                      value: _vm.invoice.ad_group,
                                      callback: function($$v) {
                                        _vm.$set(_vm.invoice, "ad_group", $$v)
                                      },
                                      expression: "invoice.ad_group"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      rules: [
                                        function(v) {
                                          return !!v || "Manufacturer Required"
                                        }
                                      ],
                                      "auto-select-first": "",
                                      outlined: "",
                                      dense: "",
                                      "background-color": "#fff",
                                      label: "Manufacturer",
                                      items: _vm.manufacturers,
                                      "item-text": "description",
                                      "item-value": "id"
                                    },
                                    model: {
                                      value: _vm.manufacturerId,
                                      callback: function($$v) {
                                        _vm.manufacturerId = $$v
                                      },
                                      expression: "manufacturerId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      rules: [
                                        function(v) {
                                          return !!v || "Description Required"
                                        }
                                      ],
                                      outlined: "",
                                      dense: "",
                                      "background-color": "#fff",
                                      label: "Invoice Description"
                                    },
                                    model: {
                                      value: _vm.invoiceDescription,
                                      callback: function($$v) {
                                        _vm.invoiceDescription = $$v
                                      },
                                      expression: "invoiceDescription"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-1",
                              staticStyle: { height: "55px" },
                              attrs: { justify: "end", dense: "" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "grey lighten-2 text-capitalize ml-2",
                                  staticStyle: { width: "150px" },
                                  attrs: { raised: "", tabindex: "-1" },
                                  on: {
                                    click: function($event) {
                                      _vm.isExistingInvoice
                                        ? _vm.addInvoice()
                                        : _vm.saveInvoice()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.isExistingInvoice
                                          ? "Add Invoice"
                                          : "Save Invoice"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { justify: "end", dense: "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "grey lighten-2 text-capitalize",
                                  staticStyle: { width: "150px" },
                                  attrs: {
                                    disabled: _vm.invoices.length <= 1,
                                    tabindex: "-1"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteInvoice(_vm.invoice)
                                    }
                                  }
                                },
                                [_vm._v(" Delete Invoice ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card",
            {
              staticClass: "white pt-4 px-6",
              attrs: { height: "415", flat: "" }
            },
            [
              _c(
                "v-row",
                [
                  _c("LumpSums", {
                    attrs: { lumpSums: _vm.lumpSums },
                    on: { add: _vm.addLumpSum, delete: _vm.deleteLumpSum }
                  }),
                  _c("CaseAllowances", {
                    attrs: { caseAllowances: _vm.caseAllowances },
                    on: {
                      add: _vm.addCaseAllowance,
                      delete: _vm.deleteCaseAllowance
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-toolbar",
            { staticClass: "grey lighten-4", attrs: { flat: "" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text-capitalize green white--text",
                  staticStyle: { width: "150px" },
                  attrs: { disabled: _vm.loading, loading: _vm.loading },
                  on: { click: _vm.processInvoice }
                },
                [_vm._v(" Process Invoice ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        { attrs: { value: _vm.alertDialog, persistent: "", width: "500" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-alert",
                {
                  staticClass: "rounded-0 white--text",
                  attrs: { dense: "", color: "amber darken-1" }
                },
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-4",
                          attrs: { color: "white", size: "50" }
                        },
                        [_vm._v(" mdi-alert-octagon ")]
                      ),
                      _vm._v(" Unsaved changes ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { attrs: { "align-text": "center" } },
                [
                  _c("v-col", { attrs: { cols: "9" } }, [
                    _vm._v(
                      " You are about the close the modal without saving. All changes will be lost. "
                    )
                  ]),
                  _c("v-col", { attrs: { cols: "8" } }, [
                    _vm._v(" Please click "),
                    _c("b", [_vm._v("Confirm")]),
                    _vm._v(" to continue. ")
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-capitalize",
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          _vm.alertDialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-capitalize",
                      on: {
                        click: function($event) {
                          return _vm.$emit("update:value", false)
                        }
                      }
                    },
                    [_vm._v(" Confirm ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }