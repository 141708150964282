<template>
  <v-dialog :value="value" persistent max-width="1300">
    <v-card class="grey lighten-4">
      <v-toolbar flat class="white--text" color="#1C3144">
        <v-btn tabindex="-1" icon class="white--text" @click="checkForInvoices">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{
            isExistingBatch ? 'Edit Manual Invoice' : 'Create Manual Invoice'
          }}
        </v-toolbar-title>
        <v-spacer />
        <v-row dense justify="end">
          <v-col lg="6" xl="6">
            <v-autocomplete
              tabindex="-1"
              :items="invoices"
              background-color="white"
              class="mt-2"
              color
              dense
              item-text="ad_group.description"
              outlined
              :placeholder="
                invoices.length
                  ? `Saved Invoices (${invoices.length})`
                  : 'No Saved Invoices'
              "
              v-model="invoice"
              return-object
              @change="viewInvoice"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-card flat height="100" class="grey lighten-4 my-6 px-6" fluid>
        <v-form ref="form">
          <v-row dense>
            <v-col md="6" lg="7" xl="7">
              <v-row dense>
                <v-col>
                  <v-autocomplete
                    :rules="[v => !!v || 'Ad Group Required']"
                    :items="adgroups.filter(({ id }) => id !== 'ALL')"
                    :readonly="isExistingInvoice"
                    auto-select-first
                    autofocus
                    background-color="#fff"
                    dense
                    item-id="id"
                    item-text="description"
                    label="Ad Group"
                    outlined
                    ref="adGroup"
                    return-object
                    v-model="invoice.ad_group"
                  />
                </v-col>
                <v-col>
                  <!-- manufacturer_id -->
                  <v-autocomplete
                    :rules="[v => !!v || 'Manufacturer Required']"
                    auto-select-first
                    outlined
                    dense
                    background-color="#fff"
                    label="Manufacturer"
                    :items="manufacturers"
                    item-text="description"
                    item-value="id"
                    v-model="manufacturerId"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <!-- batch_description -->
                  <v-text-field
                    :rules="[v => !!v || 'Description Required']"
                    outlined
                    dense
                    background-color="#fff"
                    label="Invoice Description"
                    v-model="invoiceDescription"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row justify="end" class="mt-1" style="height:55px;" dense>
                <v-btn
                  raised
                  tabindex="-1"
                  style="width:150px;"
                  class="grey lighten-2 text-capitalize ml-2"
                  @click="isExistingInvoice ? addInvoice() : saveInvoice()"
                >
                  {{ isExistingInvoice ? 'Add Invoice' : 'Save Invoice' }}
                </v-btn>
              </v-row>
              <v-row justify="end" dense>
                <v-btn
                  :disabled="invoices.length <= 1"
                  tabindex="-1"
                  style="width:150px;"
                  class="grey lighten-2 text-capitalize"
                  @click="deleteInvoice(invoice)"
                >
                  Delete Invoice
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-divider />
      <v-card height="415" flat class="white pt-4 px-6">
        <v-row>
          <LumpSums
            :lumpSums="lumpSums"
            @add="addLumpSum"
            @delete="deleteLumpSum"
          />
          <CaseAllowances
            :caseAllowances="caseAllowances"
            @add="addCaseAllowance"
            @delete="deleteCaseAllowance"
          />
        </v-row>
      </v-card>
      <v-divider />
      <v-toolbar flat class="grey lighten-4">
        <v-spacer />
        <v-btn
          :disabled="loading"
          :loading="loading"
          style="width:150px;"
          class="text-capitalize green white--text"
          @click="processInvoice"
        >
          Process Invoice
        </v-btn>
      </v-toolbar>
    </v-card>

    <v-dialog :value="alertDialog" persistent width="500">
      <v-card>
        <v-alert dense color="amber darken-1" class="rounded-0 white--text">
          <v-card-title>
            <v-icon color="white" size="50" class="mr-4">
              mdi-alert-octagon
            </v-icon>
            Unsaved changes
          </v-card-title>
        </v-alert>
        <v-card-text align-text="center">
          <v-col cols="9">
            You are about the close the modal without saving. All changes will
            be lost.
          </v-col>
          <v-col cols="8"> Please click <b>Confirm</b> to continue. </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="text-capitalize" text @click="alertDialog = false">
            Cancel
          </v-btn>
          <v-btn class="text-capitalize" @click="$emit('update:value', false)">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { tableSettings } from '@/mixins/table'
import { notification } from '@/mixins/notification'
import { isEmpty } from 'lodash'
import { mapState } from 'vuex'

export default {
  name: 'InvoiceManual',
  mixins: [tableSettings, notification],
  props: ['value', 'selectedBatch', 'getBatches'],
  components: {
    LumpSums: () => import('@/components/invoicing/LumpSums'),
    CaseAllowances: () => import('@/components/invoicing/CaseAllowances')
  },
  data() {
    return {
      alertDialog: false,
      caseAllowances: [],
      componentKey: 0,
      confirmDialog: false,
      currentInvoice: {},
      id: 0,
      invoice: {},
      invoiceDescription: '',
      invoices: [],
      items: [],
      lumpSums: [],
      manufacturerId: '',
      manufacturers: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.getManufacturers()
          if (this.isExistingBatch) {
            this.getManualInvoice()
          }
          if (!this.isExistingBatch) {
            this.addDeductionFee()
          }
        }
      }
    }
  },
  computed: {
    ...mapState(['adgroups']),
    isExistingBatch() {
      return !isEmpty(this.selectedBatch)
    },
    isExistingInvoice() {
      return this.invoice.saved
    },
    isValidInvoice() {
      return (
        ('ad_group' in this.invoice && this.lumpSums.length > 0) ||
        this.caseAllowances.length > 0
      )
    }
  },
  created() {
    this.nonTableHeight = 350
  },
  methods: {
    checkForInvoices() {
      if (
        !this.isExistingBatch &&
        (this.lumpSums.length > 0 ||
          this.caseAllowances > 0 ||
          this.invoices.length > 0)
      ) {
        this.alertDialog = true
      } else {
        this.$emit('update:value', false)
      }
    },
    addLumpSum(lumpSum) {
      this.lumpSums.unshift(lumpSum)
    },
    deleteLumpSum(lumpSum) {
      const index = this.lumpSums.indexOf(lumpSum)
      this.lumpSums.splice(index, 1)
    },
    addDeductionFee() {
      let lump = {}
      lump.lump_allow = 6
      lump.lump_description = 'Deduction Fee'
      this.addLumpSum(lump)
    },
    addCaseAllowance(caseAllowance) {
      this.caseAllowances.unshift(caseAllowance)
    },
    deleteCaseAllowance(caseAllowance) {
      const index = this.caseAllowances.indexOf(caseAllowance)
      this.caseAllowances.splice(index, 1)
    },
    async getManualInvoice() {
      try {
        const res = await this.$invoicing.getInvoiceItems(
          this.selectedBatch.batch_number,
          this.selectedBatch.invoice_number
        )
        this.invoiceDescription = res.batch_description
        this.manufacturerId = res.manufacturer_id
        this.invoices = res.adgroups.map(r => {
          r.saved = true
          return r
        })
        this.invoice = res.adgroups[0]
        this.viewInvoice()
      } catch (error) {
        this.notify('error', 'Error Retrieving Invoice')
      }
    },
    async getManufacturers() {
      try {
        const res = await this.$contracts.getManufacturers()
        this.manufacturers = res
      } catch (error) {
        console.log(error)
      }
    },
    updateInvoice(group) {
      this.invoice.adgroups.forEach(adgroup => {
        if (adgroup.ad_group.id === group.ad_group.id) {
          this.removeGroup(adgroup)
          this.invoice.adgroups.push(group)
          this.notify('success', 'Group Successfully Updated')
        }
      })
      this.modal = false
    },
    removeGroup(group) {
      const groupsIndex = this.invoice.adgroups.indexOf(group, 0)
      this.invoice.adgroups.splice(groupsIndex, 1)
    },
    async saveInvoice() {
      const valid = this.$refs.form.validate()
      if (valid) {
        try {
          const adGroup = {
            ad_group: this.invoice.ad_group,
            saved: true,
            invoice_types: [
              {
                invoice_type: 'Lump Sums (M)',
                items: this.lumpSums
              },
              {
                invoice_type: 'Case Allowances (M)',
                items: this.caseAllowances
              }
            ]
          }
          if (!this.isExistingInvoice) {
            this.invoices.push(adGroup)
            this.invoice = adGroup
            this.notify('success', 'Invoice Saved Successfully')
          }
        } catch {
          this.notify('error', 'Error Saving Invoice')
        }
      }
    },
    addInvoice() {
      this.resetInvoice()
      this.$refs.adGroup.focus()
    },
    resetInvoice() {
      this.invoice = {}
      this.lumpSums = []
      this.caseAllowances = []
    },
    deleteInvoice(invoice) {
      const index = this.invoices.indexOf(invoice)
      this.invoices.splice(index, 1)
      if (this.invoices.length) {
        this.invoice = this.invoices[0]
      }
      this.resetInvoice()
    },
    viewInvoice() {
      this.invoices.forEach(({ ad_group, invoice_types }) => {
        if (ad_group.id === this.invoice.ad_group.id) {
          this.invoice.ad_group = ad_group
          invoice_types.forEach(({ invoice_type, items }) => {
            if (invoice_type === 'Lump Sums (M)') {
              this.lumpSums = items
            }
            if (invoice_type === 'Case Allowances (M)') {
              this.caseAllowances = items
            }
          })
        }
      })
    },
    async processInvoice() {
      this.loading = true
      if (this.invoice) {
        await this.saveInvoice()
      }
      const invoice = {
        adgroups: this.invoices,
        manufacturer_id: this.manufacturerId,
        batch_description: this.invoiceDescription,
        batch_number: this.isExistingBatch
          ? this.selectedBatch.batch_number
          : null
      }
      try {
        await this.$invoicing.processManualInvoice(invoice)
        this.notify(
          'success',
          this.isExistingBatch
            ? 'Updated Invoice Successfully'
            : 'Created Invoice Successfully'
        )
        this.$emit('refresh')
        setTimeout(() => {
          this.$emit('update:value', false)
        }, 2000)
      } catch {
        this.notify('error', 'Error Processing Invoice')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
